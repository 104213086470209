import { defineStore, acceptHMRUpdate } from "pinia";

export interface adminStore {
  adminModeActive: boolean,
  showSideBar: boolean,
  toolbar: toolbar
}

export interface toolbar {
  autoHideToolBar: boolean
}

export const useAdminStore = defineStore("admin", {
  state: (): adminStore => ({
    adminModeActive: false,
    showSideBar: false,
    toolbar: {
      autoHideToolBar: true,
    },
  }),
  getters: {
    getAdminModeState: (state: adminStore): boolean => state.adminModeActive,
    getToolBarSettings: (state: adminStore): toolbar => state.toolbar,
  },
  actions: {
    setAdminMode(adminmode: boolean) {
      this.adminModeActive = adminmode;
    },

    /**
     * @description patches the toolbar state object with the settings parsed. only replacing values that are present in the param
     * @param setting
     * @example setSttings({audoHideToolBar: false})
     */
    setSettings(setting: toolbar): void {
      useAdminStore().$patch({ toolbar: setting })
    },
  },
});